.recipeStats {
	--font-family: var(--typography-body-s-family);
	--font-size: var(--typography-body-s-size);
	--font-weight: var(--typography-body-s-weight);
	--letter-spacing: var(--typography-body-s-letter-spacing);
	--line-height: var(--typography-body-s-line-height);

	@media (--bp-medium) {
		--font-family: var(--typography-body-s-desktop-family);
		--font-size: var(--typography-body-s-desktop-size);
		--font-weight: var(--typography-body-s-desktop-weight);
		--letter-spacing: var(--typography-body-s-desktop-letter-spacing);
		--line-height: var(--typography-body-s-desktop-line-height);
	}

	align-items: center;
	display: flex;
	flex-wrap: wrap;
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	gap: var(--spacing-2xs);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	margin: 0;
}

.recipeStatGroup {
	align-items: center;
	background-color: var(--color-accent2);
	border-radius: var(--radius-s);
	display: flex;
	gap: var(--spacing-3xs);
	padding: var(--spacing-3xs);
}

.recipeStatLabel {
	--font-weight: 700;

	font-weight: var(--font-weight);
}

.recipeStatLabel,
.recipeStatValue {
	margin-left: 0;
}
