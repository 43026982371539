.recipe {
	--separator-border-color: var(--color-neutral-dark-20);
	--separator-border: 1px solid var(--separator-border-color);

	border: 1px solid var(--color-accent2);
	border-radius: var(--radius-s);
	padding: var(--spacing-2xl) var(--spacing-s);

	& :global(.button) {
		align-self: flex-start;

		@media print {
			display: none;
		}
	}
}

@container style(--is-dark-mode: 1) {

	.recipe {
		--separator-border-color: var(--color-neutral-light-20);
	}
}

.recipeHeader {
	border-bottom: var(--separator-border);
	display: flex;
	flex-direction: column;
	gap: var(--spacing-l);
	padding-bottom: var(--spacing-l);
}

.recipeTitle {
	--font-family: var(--typography-h-l-family);
	--font-size: var(--typography-h-l-size);
	--font-weight: var(--typography-h-l-weight);
	--letter-spacing: var(--typography-h-l-letter-spacing);
	--line-height: var(--typography-h-l-line-height);

	@media (--bp-medium) {
		--font-family: var(--typography-h-l-desktop-family);
		--font-size: var(--typography-h-l-desktop-size);
		--font-weight: var(--typography-h-l-desktop-weight);
		--letter-spacing: var(--typography-h-l-desktop-letter-spacing);
		--line-height: var(--typography-h-l-desktop-line-height);
	}
}

.recipeDescription,
.recipePerServing {
	--font-family: var(--typography-body-s-family);
	--font-size: var(--typography-body-s-size);
	--font-weight: var(--typography-body-s-weight);
	--letter-spacing: var(--typography-body-s-letter-spacing);
	--line-height: var(--typography-body-s-line-height);

	@media (--bp-medium) {
		--font-family: var(--typography-body-s-desktop-family);
		--font-size: var(--typography-body-s-desktop-size);
		--font-weight: var(--typography-body-s-desktop-weight);
		--letter-spacing: var(--typography-body-s-desktop-letter-spacing);
		--line-height: var(--typography-body-s-desktop-line-height);
	}
}

.recipeSection {
	border-bottom: var(--separator-border);
	display: flex;
	flex-direction: column;
	gap: var(--spacing-m);
	margin: 0;
	padding: var(--spacing-l) 0;
}

.recipeSectionTitle {
	--font-family: var(--typography-h-s-family);
	--font-size: var(--typography-h-s-size);
	--font-weight: var(--typography-h-s-weight);
	--letter-spacing: var(--typography-h-s-letter-spacing);
	--line-height: var(--typography-h-s-line-height);

	@media (--bp-medium) {
		--font-family: var(--typography-h-s-desktop-family);
		--font-size: var(--typography-h-s-desktop-size);
		--font-weight: var(--typography-h-s-desktop-weight);
		--letter-spacing: var(--typography-h-s-desktop-letter-spacing);
		--line-height: var(--typography-h-s-desktop-line-height);
	}
}

.recipeGroup {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-m);
}

.recipeGroupTitle {
	--font-family: var(--typography-h-2xs-family);
	--font-size: var(--typography-h-2xs-size);
	--font-weight: var(--typography-h-2xs-weight);
	--letter-spacing: var(--typography-h-2xs-letter-spacing);
	--line-height: var(--typography-h-2xs-line-height);

	@media (--bp-medium) {
		--font-family: var(--typography-h-2xs-desktop-family);
		--font-size: var(--typography-h-2xs-desktop-size);
		--font-weight: var(--typography-h-2xs-desktop-weight);
		--letter-spacing: var(--typography-h-2xs-desktop-letter-spacing);
		--line-height: var(--typography-h-2xs-desktop-line-height);
	}
}

.recipeListItem {
	counter-reset: instructions-counter;
	display: flex;
	flex-direction: column;
}

/* Ingredients */
.recipeIngredientsList {
	list-style: circle;
	padding-left: var(--spacing-s);
	row-gap: var(--spacing-xs);
}

/* Instructions */
.recipeInstructionsList {
	counter-reset: instructions-counter;
	padding-left: 0;
	row-gap: var(--spacing-l);
}

.recipeInstructionItem {
	display: flex;
	flex-direction: column;
	padding-left: 3rem;
	position: relative;
	row-gap: var(--spacing-xs);

	&::before {
		--font-family: var(--typography-h-s-family);
		--font-size: var(--typography-h-s-size);
		--font-weight: var(--typography-h-s-weight);
		--letter-spacing: var(--typography-h-s-letter-spacing);
		--line-height: var(--typography-h-s-line-height);

		@media (--bp-medium) {
			--font-family: var(--typography-h-s-desktop-family);
			--font-size: var(--typography-h-s-desktop-size);
			--font-weight: var(--typography-h-s-desktop-weight);
			--letter-spacing: var(--typography-h-s-desktop-letter-spacing);
			--line-height: var(--typography-h-s-desktop-line-height);
		}

		content: counter(instructions-counter, decimal-leading-zero);
		counter-increment: instructions-counter;
		font-family: var(--font-family);
		font-size: var(--font-size);
		font-variant-numeric: tabular-nums;
		font-weight: var(--font-weight);
		left: 0;
		letter-spacing: var(--letter-spacing);
		line-height: var(--line-height);
		position: absolute;
		top: 3px;
	}
}


/* Remove the margin-top from the recipe list item */
:global(.content) .recipeIngredientListItem,
:global(.content) .recipeInstructionItem {
	margin-top: 0;
}

.recipeInstructionsMedia {
	position: relative;
}

.recipeInstructionsImage {
	--border-radius: var(--radius-media);

	border-radius: var(--border-radius);
	height: auto;
	width: 100%;
}

/* Nutrition Facts */
.recipeNutritionFactsHead {
	align-items: center;
	column-gap: var(--spacing-2xs);
	display: flex;
}

.recipeNutritionFactsList {
	display: flex;
	flex-direction: column;
	margin: 0;
	row-gap: var(--spacing-xs);
}

.recipeNutritionFactsItem {
	align-items: center;
	column-gap: var(--spacing-xs);
	display: flex;
	justify-content: space-between;
}

.recipeNutritionFactsLabel {
	--font-family: var(--typography-eyebrow-family);
	--font-size: var(--typography-eyebrow-size);
	--font-weight: var(--typography-eyebrow-weight);
	--letter-spacing: var(--typography-eyebrow-letter-spacing);
	--line-height: var(--typography-eyebrow-line-height);

	@media (--bp-medium) {
		--font-family: var(--typography-eyebrow-desktop-family);
		--font-size: var(--typography-eyebrow-desktop-size);
		--font-weight: var(--typography-eyebrow-desktop-weight);
		--letter-spacing: var(--typography-eyebrow-desktop-letter-spacing);
		--line-height: var(--typography-eyebrow-desktop-line-height);
	}

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	text-transform: uppercase;
}

.recipeNutritionFactsDivider {
	background: none;
	border-bottom: 1px solid var(--separator-border-color);
	border-top: none;
	flex-grow: 1;
	margin: 0;
}

.recipeNutritionFactsValue {
	margin-left: 0;
}

.recipeFooter {
	padding: var(--spacing-s) var(--spacing-xs) 0;
}

.recipeAttribution {
	--font-family: var(--typography-caption-family);
	--font-size: var(--typography-caption-size);
	--font-weight: var(--typography-caption-weight);
	--letter-spacing: var(--typography-caption-letter-spacing);
	--line-height: var(--typography-caption-line-height);

	@media (--bp-medium) {
		--font-family: var(--typography-caption-desktop-family);
		--font-size: var(--typography-caption-desktop-size);
		--font-weight: var(--typography-caption-desktop-weight);
		--letter-spacing: var(--typography-caption-desktop-letter-spacing);
		--line-height: var(--typography-caption-desktop-line-height);
	}
}
