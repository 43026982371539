.gallery {
	max-width: var(--screen-width);

	@media (--bp-large) {
		margin-inline: var(--margin-full-width);
	}
}

.galleryContainer {
	--num-columns: 3;
	--gallery-gutter: var(--spacing-s);

	align-items: start;
	display: flex;
	flex-wrap: wrap;
	gap: var(--gallery-gutter);
	margin-inline: auto;
	max-width: var(--max-width-grid);
}

.galleryItem {

	@media (--bp-medium) {
		width: calc(100% / var(--num-columns) - ((var(--num-columns) - 1) / var(--num-columns)) * var(--gallery-gutter));
	}
}

.galleryCropped {

	& .galleryContainer {
		align-items: stretch;
	}

	& .galleryItem {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
}
